import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

const TermsOfUse = () => {
    useEffect(() => { 
        document.title = 'SCOTUSApp - Terms of Use' 
    }, []);

    return (
        <Container className='my-3'>
            <div className='terms pane w-100 bg-light text-dark'>
                <header className='text-center mb-4'>
                    <h2>SCOTUSApp User Agreement Terms and Conditions ("Terms")</h2>
                </header>
                <section className='my-3' id='intro'>
                    <p>The University of Kentucky ("UK") through its College of Arts and Sciences, Department of Political Science ("PS"), as supported by a National Science Foundation grant, has developed a web application to compile media coverage of the U.S. Supreme Court and analyze the breadth and content of coverage (the "SCOTUSApp").</p>
                    <p>As used in these Terms, "University of Kentucky", "UK", "we", or "our" refers to the University of Kentucky, affiliates of the University of Kentucky, and board members, officers, employees, students, and agents of the University of Kentucky and such affiliates.</p>
                    <p>You must accept these Terms to create a SCOTUS App account ("Account") and to use the services available through the SCOTUSApp. If you do not have an account, you accept these Terms by registering for access to the SCOTUSApp (<Link to='/register/' className='text-dark link'>https://scotusapp.uky.edu/register</Link>) or by logging into <Link to='/login/' className='text-dark link'>https://scotusapp.uky.edu/login</Link> or by using any part of the SCOTUSApp or the App Content (defined below).</p>
                    <p>UK may revise these Terms from time to time without notice.</p>
                    <p>BY ACCESSING THE SCOTUSAPP, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS.</p>
                </section>
                <section className='my-3' id='creatingAccount'>
                    <h3 className='mb-3'>1. Creating an Account</h3>
                    <p>Full use of the SCOTUSApp requires that you create an Account by providing UK with information identifying yourself and your intended use of the information available through the SCOTUSApp, a valid email address and strong password. You agree that you will be the only user of your account and not share it with others. You are responsible for all activity that occurs in association with your account. UK is not liable for any claims, costs, losses or damages directly or indirectly caused by your failure to maintain the confidentiality of your Account credentials. You agree to notify UK immediately of any security breach or unauthorized use of your Account.</p>
                </section>
                <section className='my-3' id='acceptableUse'>
                    <h3 className='mb-3'>2. Acceptable Use</h3>
                    <p>The SCOTUSApp is intended for your individual, non-commercial use and may only be used for non-commercial research and educational purposes. Users of the SCOTUSApp may only use it in ways consistent with applicable law.</p>  
                    <p>"App Content" includes any text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the SCOTUSApp to you. App Content, the SCOTUSApp, and its underlying technology are protected by copyright, trademark, patent, and other intellectual property, and other laws of the Commonwealth of Kentucky, United States, and foreign countries. App Content includes materials that are copyrighted by non-UK third parties. You agree not to remove, change, or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the SCOTUSApp.</p>
                    <p>UK grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and view the App Content solely for the uses permitted in these Terms. This license is provided solely for your individual use of the SCOTUSApp as permitted in these Terms.</p>
                    <p>You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the SCOTUSApp, App Content, or any portion of them, except as expressly permitted in these Terms. UK does not grant any express or implied right to App Content to users of the SCOTUSApp beyond that explicitly stated in these Terms.</p>
                </section>
                <section className='my-3' id='prohibitedUse'>
                    <h3 className='mb-3'>3. Prohibited Use</h3>
                    <p>Prohibited use includes, but is not limited to the following:</p>
                    <p>Unauthorized use, destruction or impairment of the SCOTUSApp or any component thereof.</p>
                    <p>Gaining or attempting to gain unauthorized access to the SCOTUSApp, other accounts, or computer systems or networks connected to the SCOTUSApp, through hacking, password mining or any other means, including without limitation attempting to or circumventing data protection methods or algorithms, decrypting user passwords, accessing protected files, or uncovering security loopholes.</p> 
                    <p>Using the SCOTUSApp or App Content for commercial activities or product advertisements or re-publishing the App Content.</p>
                </section>
                <section className='my-3' id='enforcementRights'>
                    <h3 className='mb-3'>4. UK's Enforcement Rights</h3>
                    <p>UK is not obligated to monitor access or use of the SCOTUSApp or App Content, or to review or edit any App Content, but UK has the right to do so to operate the SCOTUSApp, ensure compliance with these Terms, and comply with applicable law or other legal requirements. UK has the right to investigate violations of these Terms and any conduct that affects the SCOTUSApp.</p>
                </section>
                <section className='my-3' id='copyrightPolicy'>
                    <h3 className='mb-3'>5. DMCA/Copyright Policy</h3>
                    UK respects copyright law and expects its users to do the same. It is UK's policy to terminate in appropriate circumstances account holders who repeatedly infringe the rights of copyright holders. For purposes of the Digital Millennium Copyright Act ("DMCA"), UK has designated an agent for notices of claimed infringement. If you have any objections governed by the DMCA, please contact the agent listed under the last section of these Terms below. UK provides this contact information for purposes of the DMCA only and reserves the right to respond to communication that is relevant for this purpose. However, UK does not warrant that use of any of the App Content will not infringe the rights of third parties.
                </section>
                <section className='my-3' id='privacy'>
                    <h3 className='mb-3'>6. Privacy</h3>
                    <section className='my-2'>
                        <h4 className='mb-3'>a. How UK Gathers Information</h4>
                        <p>You provide UK with information when you create an Account, such as your name, email address and password, and purpose for accessing the App Content. This information is required for Account creation. 
                        The information we receive depends on what you do when visiting the SCOTUSApp. If you visit the SCOTUSApp to read or download information, we collect and store, without limitation, the following types of information about you:  the Internet Protocol (IP) address through which you accessed the App; the date and time you access the App; and the type of browser you are using to access the App.  Related information that may be gathered includes:  host or proxy address; requested resource; whether the request succeeded or failed; the size of the resource; browser version; referring web site (if any).</p>
                    </section>
                    <section className='my-2'>
                        <h4 className='mb-3'>b. How UK Uses Information</h4>
                        <h5 className='my-2'>i. Compelled Disclosure</h5>
                        <p>UK reserves the right to use or disclose your personal information if required by law or if UK reasonable believes that use or disclosure is necessary to protects its rights, protect your safety or the safety of others, investigate fraud, or comply with a law, court order, or legal process. UK is subject to the Kentucky Open Records Act, KRS 61.100, et. seq., and information in and related to the SCOTUSApp may, dependent on the application of applicable law, be required to be disclosed pursuant to such law.</p>
                        <h5 className='my-2'>ii. UK will not sell your Personal Information</h5>
                        <p>UK will not sell, rent, transfer, or disclose your personal information to advertisers or other third parties for direct marketing purposes.</p>
                        <h5 className='my-2'>iii. Cookies and similar technologies</h5>
                        <p>UK may use cookies or similar technologies (such as web beacons) to analyze trends, administer its services, track users' movements around the SCOTUSApp, and to gather demographic information about UK's user base as a whole.</p>
                    </section>
                    <section className='my-2'>
                        <h4 className='mb-3'>c. How you can learn more</h4>
                        <p>If you have specific questions about your data privacy, please use the contact information provided in the last section of these Terms below.</p> 
                    </section>
                </section>
                <section className='my-3' id='disclaimersDisclosures'>
                    <h3 className='mb-3'>7.	Disclaimers and Disclosures</h3>
                    <p>Any access to the SCOTUSApp is voluntary and at the sole risk of the user. The SCOTUSApp and App Content are provided "as is," without warranty of any kind. Without limiting the foregoing, UK explicitly disclaims any warranties of merchantability, fitness for a particular purpose, quiet enjoyment or non-infringement, any warranties arising out of course of dealing or usage of trade. UK makes no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of the SCOTUSApp or App Content. The Content contained on the SCOTUSApp is intended for user education and research purposes only.</p>
                    <p>UK DOES NOT WARRANT THAT THE SCOTUSAPP WILL BE UNINTERRUPTED OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL CODE, OR THAT ANY ERRORS OR DEFECTS WILL BE CORRECTED. UK ASSUMES NO RESPONSIBILITY, AND WILL NOT BE LIABLE, FOR (I) ANY ERRORS OR OMISSIONS IN THE APP CONTENT, (II) DAMAGE TO OR VIRUSES THAT MAY INFECT YOUR ELECTRONIC EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR USE OF THE SCOTUSAPP OR YOUR DOWNLOADING OF ANY CONTENT FROM THE SCOTUSAPP OR OTHER APPS, OR (III) ANY DAMAGE ARISING IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, OR LINE OR SYSTEM FAILURE.</p> 
                    <p>No advertising, sponsorship, paid insertions or other forms of compensation for endorsements are made to UK or by UK in connection with the SCOTUSApp or App Content except that UK acknowledges that the National Science Foundation has provided a grant to UK to support the cost of development, distribution, and management of the SCOTUSApp or App Content for the purposes of academic research. All third-party content included in the App Content is done without endorsement of any third-party by UK, and that third-party's content is included without endorsement of UK, the SCOTUSApp or App Content. All product and service marks contained in the SCOTUSApp that are not UK marks are the trademarks of their respective owners.</p>
                </section>
                <section className='my-3' id='indemnity'>
                    <h3 className='mb-3'>8. Indemnity</h3>
                    <p>To the extent and in the manner provided by applicable law, you will indemnify and hold harmless UK and its officers, directors, employees, and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable attorneys' fees and costs arising out of or in any way connected with your (i) access to or use of the SCOTUSApp or App Content, (ii) breach of any warranties made by you under these Terms, (iii) violation of any other provision of these Terms, or (iv) violation of any third-party right. UK reserves the right to assume control of the defense of any third-party claim that is subject to indemnification by you, in which event you will cooperate with UK in asserting any available defenses.</p>
                </section>
                <section className='my-2' id='limitationOfLiability'>
                    <h3 className='mb-3'>9. Limitation of Liability</h3>
                    <p>Neither UK, its suppliers or licensors, nor any party involved in creating, producing, or delivering the SCOTUSApp or App Content will be liable for any direct, indirect, incidental, special, exemplary or consequential damages, including lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute services arising out of or in connection with these Terms or from the use of inability to use the SCOTUSApp or App Content, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not UK has been informed of the possibility of such damage, even if a limited remedy set forth in these Terms is found to have failed its essential purpose. The exclusions and limitations of damages set forth above are fundamental elements of the basis of the bargain between UK and you.</p>
                </section>
                <section className='my-3' id='law'>
                    <h3 className='mb-3'>10. Choice of Law/Governing Law/Jurisdiction and Venue</h3>
                    <p>These Terms and the resolution of any disputes under them or regarding the SCOTUSApp or App Content shall be governed by and construed in accordance with the laws of the Commonwealth of Kentucky without regards to its conflict of laws principles. Any dispute between UK and any user of the SCOTUSApp or App Content and/or arising out of or in connection with or relating to these Terms may be brought in Franklin Circuit Court in Frankfort, Kentucky in the Commonwealth of Kentucky, and UK and each user consents to the personal jurisdiction and exclusive venue of this court.  Notwithstanding any other terms or conditions of these Terms, neither of UK nor you, nor your institution, to the extent that one of them is a state agency or corporation eligible to claim privileges or immunities under applicable law under the laws of its or their jurisdiction shall be deemed to have waived any privileges or immunities that might be available to it under applicable law.</p>
                </section>
                <section className='my-3' id='generalTerms'>
                    <h3 className='mb-3'>11. General Terms</h3>
                    <p>Except as otherwise stated herein, these Terms constitute the entire and exclusive  understanding and agreement between UK and you regarding the SCOTUSApp and App Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between UK and you regarding the SCOTUSApp and App Content. UK expressly reserves all rights that it does not expressly grant in these Terms.</p>
                    <p>If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.</p>
                    <p>You may not assign or transfer these Terms, by operation of law or otherwise, without UK's prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and void. UK may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>
                    <p>Any notices or other communications provided by UK under these Terms, including those regarding modifications to these Terms, will be given by posting to the SCOTUSApp.</p>
                    <p>UK's failure to enforce any right or provision of these Terms will not be considered a waiver of such right of provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of UK.</p>
                    <p>Except as expressly set forth in these Terms, the exercise of either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</p>
                    <p>Subject to any agreements UK may have with any licensors and suppliers, UK reserves the right to change or discontinue features and specifications of the SCOTUSApp and App Content at any time and without notice.</p>
                </section>
                <section className='my-3' id='contactInfo'>
                    <h3 className='mb-3'>12. Contact Information</h3>
                    <p>Should you have questions regarding these Terms, the App or App Content, contact the study's Principal Investigators, Justin Wedeking and Michael Zilis, Department of Political Science, University of Kentucky or the University of Kentucky Research Foundation, 109 Kinkead Hall, Lexington KY 40526-0001, (859) 257-9420.</p>
                </section>
            </div>
        </Container>
    )
}

export default TermsOfUse;
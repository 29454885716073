import { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Form, Button, Stack } from 'react-bootstrap';
import { Formik } from 'formik';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DeleteProfile from './DeleteProfile.component';
import ChangePassword from './ChangePassword.component';
import useAuth from '../../hooks/useAuth';


const Profile = () => {
    const [msg, setMsg] = useState({text: '', variant: 'success', dismissed: false});
    const [profile, setProfile] = useState({name: '', email: '', bio: ''});
    const axiosPrivate = useAxiosPrivate();
    const {auth, setAuth} = useAuth();

    useEffect(() => {
        document.title = 'SCOTUSApp - Profile';
        const getProfile = async () => {
            try {
                const response = await axiosPrivate.get("/all/getProfile/");
                setProfile(response.data);
            }
            catch(err) {
                console.error(err);
            }
            
        }
        getProfile();
    },[]);

    const validate = (values) => {
        const errors = {};
        if(!values?.name?.length) {
            errors.name = 'Required.';
        }

        const emailre = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!values?.email?.length) {
            errors.email = 'Required.';
        }
        else if(!emailre.test(values.email)) {
            errors.email = 'Invalid email.';
        }
        else if(!values.email.toLowerCase().endsWith(".edu")) {
            errors.email = 'Email must be a .edu address.';
        }
        
        if(values?.bio?.length > 1000) {
            errors.bio = 'Exceeds maximum length.';
        }

        return errors;
    };

    const valuesHaveChanged = (values) => Object.keys(values).some(key => values[key] !== profile[key]);

    return (
        <Container>
             <section id='profile' className='pane bg-light text-dark'>
                <header className='mb-3 text-center'>
                    <h2>Profile</h2>
                </header>
                <Formik
                    enableReinitialize
                    initialValues= {{
                        name: profile.name,
                        email: profile.email,
                        bio: profile.bio
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                        try {
                            const response = await axiosPrivate.post("/all/updateProfile/", values);
                            const { changedFields } = response.data;
                            if(changedFields.includes('name') || changedFields.includes('email')) {
                                setAuth({
                                    ...auth,
                                    user: {
                                        ...auth.user,
                                        name: values.name,
                                        email: values.email
                                    }
                                });
                            }
                            if(changedFields) {
                                setProfile({...values});
                                setMsg({
                                    text: `User fields updated: ${changedFields.join(', ')}`, 
                                    variant: 'success',
                                    dismissed: false
                                });
                            }  
                        }
                        catch(err) {
                            console.error(err);
                            const errmsg = err?.response?.data || 'Something went wrong, please try again.';
                            if(err?.response?.status === 409) {
                                actions.setFieldError('email', errmsg);
                            }
                            else {
                                setMsg({text: errmsg, variant: 'danger', dismissed: false });
                            }
                        }
                        finally {
                            actions.setSubmitting(false);
                        }
                    }}
                >
                {formik => (
                    <Form onSubmit={formik.handleSubmit}>
                        { msg?.text && !msg?.dismissed && 
                            <Alert 
                                variant={msg.variant}
                                onClose={() => setMsg({...msg, dismissed: true})} 
                                dismissible
                            >
                                {msg.text}
                            </Alert>
                        }
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlId='name-field'>
                                    <Form.Label>Name</Form.Label>
                                    {formik.touched.name && formik.errors.name && (
                                        <Alert variant='danger'>{formik.errors.name}</Alert>
                                    )}
                                    <Form.Control 
                                        type='text'
                                        name='name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        placeholder={profile.name}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlId='email-field'>
                                    <Form.Label>Email</Form.Label>
                                    {formik.touched.email && formik.errors.email && (
                                        <Alert variant='danger'>{formik.errors.email}</Alert>
                                    )}
                                    <Form.Control 
                                        type='text'
                                        name='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        placeholder={profile.email}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlId='bio-field'>
                                    <Form.Label>Bio</Form.Label>
                                    {formik.touched.bio && formik.errors.bio && (
                                        <Alert variant='danger'>{formik.errors.bio}</Alert>
                                    )}
                                    <Form.Control 
                                        as='textarea'
                                        rows={5}
                                        name='bio'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik?.values?.bio || ''}
                                        placeholder={profile.bio}
                                    />
                                    <Form.Text id="notesHelpBlock" muted>
                                        Why you want to use SCOTUSApp, any necessary information, etc. (1000 characters max)
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Stack direction="horizontal" gap={2} className="justify-content-center my-2">
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={formik.isSubmitting || !valuesHaveChanged(formik.values)}
                                >
                                    Save Changes
                                </Button>
                                <ChangePassword setMsg={setMsg} />
                                <DeleteProfile />
                            </Stack>
                        </Row>
                    </Form>
                )}
                </Formik>
             </section>
        </Container>
    )
}

export default Profile
import React from 'react';
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";

const SimilarsTable = ({ data, ...props }) => {
    const renderArticleLinks = (value, tableMeta) => {
        const id = tableMeta.rowData[0];
        return (
            <Link to={`/article/${id}/`} className="article-link text-white">
                {value}
            </Link>
        );
    }

    const cols = [
        { 
            name: "sim_id", 
            label: "Article ID", 
            options: {
                filter: false,
                sort: true,
                customBodyRender: renderArticleLinks
            }
        },
        { 
            name: "published", 
            label: "Date/Time Published",
            options: {
                filter: false,
                sort: true,
                customBodyRender: renderArticleLinks
            }
        },
        { 
            name: "sim_source", 
            label: "Source",
            options: {
                filter: false,
                sort: true,
                customBodyRender: renderArticleLinks
            }
        },
        { 
            name: "title", 
            label: "Title",
            options: {
                filter: false,
                sort: true,
                customBodyRender: renderArticleLinks
            }
        },
        { 
            name: "sim_score", 
            label: "Similarity Score",
            options: {
                filter: false,
                sort: true,
                customBodyRender: renderArticleLinks
            }
        }
    ];
    
    const tableOptions = {
        selectableRows: 'none',
        responsive: 'simple',
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        download: false,
        pagination: true,
        rowsPerPage: 5,
        rowsPerPageOptions: [5]
    }

    return (
        <MUIDataTable
            {...props}
            data={data || []}
            columns={cols}
            options={tableOptions}
        />
    )
}

export default SimilarsTable;
import React, { useEffect } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import axios from '../axios';
import {useNavigate} from "react-router-dom";

function Register() {
    const navigate = useNavigate();
    const emailre = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const controlChars  = /[\x00-\x1F\x7F]/;

    useEffect(() => { document.title = 'SCOTUSApp - Register' }, []);

    function validate(values) {
        const errors = {};

        if(!values.name)
            errors.name = 'Required.';

        if(!values.email)
            errors.email = 'Required.';
        else if(!emailre.test(values.email))
            errors.email = 'Not a valid email.';
        else if(!values.email.toLowerCase().endsWith(".edu"))
            errors.email = 'Supplied email is not a .edu address.';

        if(!values.password)
            errors.password = 'Required.';
        else if(!values.confirm_password)
            errors.confirm_password = 'Required.';
        else if(values.password !== values.confirm_password)
            errors.confirm_password = 'Confirm Password does not match Password field.';
        else if(values.password.length < 8)
            errors.password = 'Password is not long enough (minimum 8 characters).';
        else if (controlChars.test(values.password))
            errors.password = 'Password contains invalid characters.';
        
        if(values.notes && values.notes.length > 1000)
            errors.notes = 'Field is too long (maximum: 1000 characters).';
        
        if(!values.tou_agree)
            errors.tou_agree = 'Must agree to the terms of use.';
      
        return errors;
    };


    return (
        <Container>
            <section id='register' className="pane bg-light text-dark">
                <header className='text-center'>
                    <h2>Register</h2>
                </header>
                <article className='my-4'>
                    SCOTUSApp can only be used by verified accounts - 
                    once you register, your information (minus password) will be sent to our administrators for vetting. 
                    Upon verification, you will be sent a success email and can begin use of SCOTUSApp.
                </article>
                <Formik
                    initialValues={{name: '', email: '', password: '', confirm_password: '', notes: '', tou_agree:false}}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                        try {
                            const resp = await axios.post("/register/",values);
                            navigate("/login", {
                                state: { msg: resp.data }
                            });
                        }
                        catch(err) {
                            const errmsg = err.response ? err.response.data : err.message;
                            const field = (err.response && err.response.status === 409) ? "email" : "general";
                            actions.setFieldError(field,errmsg);
                        }
                        actions.setSubmitting(false);
                    }}
                >
                {formik => (
                    <Form onSubmit={formik.handleSubmit}>
                        {formik.errors.general ? 
                            (<Alert key="gen-err" variant="danger">{formik.errors.general}</Alert>) : null}
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            {formik.touched.name && formik.errors.name ? 
                                (<Alert key="name-err" variant="danger">{formik.errors.name}</Alert>) : null}
                            <Form.Control type="text" name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email-field">
                            <Form.Label>Email</Form.Label>
                            {formik.touched.email && formik.errors.email ? 
                                (<Alert key="email-err" variant="danger">{formik.errors.email}</Alert>) : null}
                            <Form.Control type="email" name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}/>
                            <Form.Text id="emailHelpBlock">
                                Must be a .edu address.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password-field">
                            <Form.Label>Password</Form.Label>
                            {formik.touched.password && formik.errors.password ? 
                                (<Alert key="pass-err" variant="danger">{formik.errors.password}</Alert>) : null}
                            <Form.Control type="password" name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}/>
                            <Form.Text id="passwordHelpBlock" muted>
                                Your password must be at least 8 characters.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="confirm-password-field">
                            <Form.Label>Confirm Password</Form.Label>
                            {formik.touched.confirm_password && formik.errors.confirm_password ? 
                                (<Alert key="confpass-err" variant="danger">{formik.errors.confirm_password}</Alert>) : null}
                            <Form.Control type="password" name="confirm_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirm_password}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="notes-field">
                            <Form.Label>Bio</Form.Label>
                            {formik.touched.notes && formik.errors.notes ? 
                                (<Alert key="notes-err" variant="danger">{formik.errors.notes}</Alert>) : null}
                            <Form.Control as="textarea" rows={5} name="notes" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.notes}/>
                            <Form.Text id="notesHelpBlock" muted>
                                Why you want to use SCOTUSApp, any necessary information, etc. (1000 characters max)
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tou-field">
                            {formik.touched.tou_agree && formik.errors.tou_agree ? 
                                    (<Alert key="tou-err" variant="danger">{formik.errors.tou_agree}</Alert>) : null}
                            <Form.Check type="checkbox" label="I agree to the SCOTUSApp Terms of Use." name="tou_agree"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.tou_agree}/>
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="primary" type="submit" size="lg" disabled={formik.isSubmitting}>
                                Sign Up
                            </Button>
                        </div>
                    </Form>
                )}
                </Formik>
            </section>
        </Container>
    );

};

export default Register;
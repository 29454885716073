import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Select, { createFilter, components } from 'react-select';
import { Badge, Button, Form } from 'react-bootstrap';
import { buildSourceOptions, organizeSources } from "../../utils";
import { useSearchParams } from 'react-router-dom';

const SourceFilter = ({criteria,results}) => {
    const [, setSearchParams] = useSearchParams();
    const [visibleGroups, setVisibleGroups] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            filteredSources: buildSourceOptions(criteria.filteredSources,results.resultSources)
        },
        validate: () => {},
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            const sourceValues = values.filteredSources.map(source => {return source.value});
            setSearchParams({
                ...criteria,
                page: 0,
                filteredSources: sourceValues
            })
            actions.setSubmitting(false);
        }
    });

    useEffect(() => {
        if(!criteria.filteredSources.length)
            formik.setFieldValue('filteredSources',[]);
    }, [criteria.filteredSources]);

    const selectStyle = { 
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        option: base => ({...base, color: '#000'}),
        control: base => ({...base, color: '#000'}),
        singleValue: (base) => ({...base, color: '#000'}),
        group: (base) => ({...base, paddingTop: 0, paddingBottom: 0}),
    };

    const CustomOption = (props) => {
        const { data : { group } } = props;
        return (visibleGroups.includes(group) || isFiltering) && (
            <components.Option {...props} />
        )
    };

    const CustomGroup = (props) => (
        <div className='source-filter-group'>
            <components.Group {...props} />
            <hr />
        </div>
    );

    const collapsibleGroupLabel = ({ label: group, options }) => {
        // toggle group visibility when group label is clicked
        const onClick = () => {
            if(!isFiltering) {
                const groupIndex = visibleGroups.findIndex((visibleGroup) => visibleGroup === group);
                const groupIsVisible = groupIndex >= 0;

                if(!groupIsVisible) { // group is collapsed, add it to visible list
                    setVisibleGroups((prevGroups) => [
                        ...prevGroups, 
                        group
                    ]);
                }
                else { // group is already visible, collapse it
                    const newVisibleGroups = [...visibleGroups]
                    newVisibleGroups.splice(groupIndex); //
                    setVisibleGroups(newVisibleGroups);
                }
            }
        }
        
        return (
            <div style={{fontSize: '1.75rem', paddingTop: '5px', paddingBottom: '5px'}}>
                <div onClick={onClick} style={{width: 'fit-content', cursor: 'pointer'}}>
                    <span style={{color: '#0d6efd', fontWeight:'bold'}}>{group}</span>
                    <Badge className='ms-2' size='lg'>
                        {options?.length?.toLocaleString() || null}
                    </Badge>
                </div>
            </div>
        )
    };

    const formatOptionLabel = ({ label, cnt }) => (
        <div style={{ display: "flex" }}>
            <div>{label}</div>
            {cnt !== null && (
                <div style={{ marginLeft: "5px" }}>
                    <Badge className='text-dark' bg="warning">
                        {cnt?.toLocaleString() || null}
                    </Badge>
                </div>
            )}
        </div>
    );

    const filterOption = createFilter({ ignoreAccents: false }); 
    const onSelect = (selected) => formik.setFieldValue('filteredSources', selected);
    const organizedOptions = organizeSources(results.resultSources);

    const handleInputChange = (input) => {
        if(input && !isFiltering) {
            setIsFiltering(true);
        }
        else if(!input && isFiltering) {
            setIsFiltering(false);
        }
        return input;
    };

    const onMenuClose = () => {
        setVisibleGroups([]);
        setIsFiltering(false);
    };

    return (
        <div className="bg-white text-dark pane w-100 source-filter">
            <h3>
                Filter Sources
                <Badge className='ms-2' bg="primary">
                    {results?.resultSources?.length?.toLocaleString() || null}
                </Badge>
            </h3>
            <Form onSubmit={formik.handleSubmit}>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={organizedOptions}
                        menuPortalTarget={document.body} 
                        styles={selectStyle}
                        onChange={onSelect}
                        onBlur={formik.handleBlur}
                        value={formik.values.filteredSources}
                        maxMenuHeight={600}
                        formatOptionLabel={formatOptionLabel}
                        formatGroupLabel={collapsibleGroupLabel}
                        filterOption={filterOption}
                        onInputChange={handleInputChange}
                        components={{ Option: CustomOption, Group: CustomGroup }}
                        onMenuClose={onMenuClose}
                    />
                    <Button className="mt-3" variant="success" type="submit" size="md" disabled={formik.isSubmitting}>
                        Filter Sources
                    </Button>
            </Form>
        </div>
    );
}

export default SourceFilter;
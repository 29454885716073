import { Row, Col } from 'react-bootstrap';
import MBMCarousel from './MBMCarousel.component';
import { isNumber } from '../../utils';

const SourceData = ({ data }) => {
    const allsidesCommunityAgreement = () => {
        const { allsides_agree = null, allsides_disagree = null } = data;
        if(isNumber(allsides_agree) && isNumber(allsides_disagree)) {
            let percentage = allsides_agree / (allsides_agree + allsides_disagree) * 100
            percentage = percentage.toFixed(2);
            return (
                <>
                    <div>{percentage}%</div>
                    <div>
                        <small className='text-success'>{allsides_agree?.toLocaleString()} Agree</small>
                    </div>
                    <div>
                        <small className='text-danger'>{allsides_disagree?.toLocaleString()} Disagree</small>
                    </div>
                </>
            );
        } 
        else {
            return 'N/A';
        }
    }

    return (
        <>
            <Row className='mt-2'>
                <Col xs={12} md={6}>
                    <Row>
                    { data?.allsides_id ? [
                        <h3 className='mb-3' key='als-header'>
                            <a 
                                className='link text-allsides'
                                href={`https://www.allsides.com/news-source/${data.allsides_id}`}
                                target="_blank" 
                                rel="noopener"
                            >
                                Allsides
                            </a>
                        </h3>,
                        <Col xs={12} sm={6} md={4} lg={3} key='als-bias'>
                            <h6 className='text-allsides'>Bias</h6>
                            {data?.allsides_bias}
                        </Col>,
                        <Col xs={12} sm={6} md={4} lg={3} key='als-score'>
                            <h6 className='text-allsides'>Bias Score</h6>
                            {data?.allsides_bias_score}
                        </Col>,
                        <Col xs={12} sm={6} md={4} lg={3} key='als-z'>
                            <h6 className='text-allsides'>Z-Score</h6>
                            {data?.allsides_z}
                        </Col>,
                        <Col xs={12} sm={6} md={4} lg={3} key='als-conf'>
                            <h6 className='text-allsides'>Confidence</h6>
                            {data?.allsides_confidence}
                        </Col>,
                        <Col xs={12} sm={12} md={6} key='als-comm-agree'>
                            <h6 className='text-allsides'>Community Agreement</h6>
                            {allsidesCommunityAgreement()}
                        </Col>
                        ] : [<h3 className='mb-3 text-allsides' key='als-header'>Allsides</h3>, <Col key='als-no-data' xs={12}>No Data</Col>]
                    }
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                    {data?.mbfc_id ? [
                        <h3 className='mb-3' key='mbfc-header'>
                            <a
                                className='link text-mbfc'
                                href={`https://mediabiasfactcheck.com/${data.mbfc_id}`}
                                target="_blank"
                                rel="noopener"
                            >
                                Media Bias Fact Check
                            </a>
                        </h3>,
                        <Col xs={12} sm={6} key='mbfc-bias'>
                            <h6 className='text-mbfc'>Bias</h6>
                            {data?.mbfc_bias}
                        </Col>,
                        <Col xs={12} sm={6} key='mbfc-score'>
                            <h6 className='text-mbfc'>Bias Score</h6>
                            {data?.mbfc_bias_score}
                        </Col>,
                        <Col xs={12} sm={6} key='mbfc-z'>
                            <h6 className='text-mbfc'>Z-Score</h6>
                            {data?.mbfc_z}
                        </Col>,
                        <Col xs={12} sm={6} key='mbfc-fact'>
                            <h6 className='text-mbfc'>Factual Reporting</h6>
                            {data?.mbfc_factual_reporting}
                        </Col>
                        ] : [<h3 className='mb-3 text-mbfc' key='mbfc-header'>Media Bias Fact Check</h3>, <Col key='mbfc-no-data' xs={12}>No Data</Col>]
                    }
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={12}>
                    <h3 className='mb-3 text-mbm'>Media Bias Monitor</h3>
                    {![null,undefined].includes(data?.mbm_score) ? (
                            <>
                                <Row>
                                    <Col xs={12} md={2}>
                                        <h6 className='text-mbm'>Bias Score</h6>
                                        {data?.mbm_score}
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <h6 className='text-mbm'>Z-Score</h6>
                                        {data?.mbm_z}
                                    </Col>
                                </Row>
                                <MBMCarousel data={data} />
                            </>
                        )
                        : <Col xs={12}>No Data</Col>
                    }
                </Col>
            </Row>
        </>

)}

export default SourceData;
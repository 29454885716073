const getFilenameFromHeader = (content_disposition) => {
    const match = /filename="(.+)"/.exec(content_disposition);
    return match ? match[1] : null;
};

const getCriteriaFromParams = params => ({
    title: params.get('title') || '',
    body: params.get('body') || '',
    keywords: params.getAll('keywords')?.length > 0 ? params.getAll('keywords') : [],
    // logic: 'OR',
    from: params.get('from') || '',
    to: params.get('to') || '',
    sources: params.getAll('sources')?.length ? params.getAll('sources').map((source) => Number(source)) : [],
    ids: params.getAll('ids')?.length ? params.getAll('ids').map((id) => Number(id)) : [],
    page: params.get('page')?.length ? Number(params.get('page')) : 0,
    limit: params.get('limit')?.length ? Number(params.get('limit')) : 15,
    sortBy: params.get('sortBy') || 'article_id',
    sortDirection: params.get('sortDirection') || 'desc',
    filteredSources: params.getAll('filteredSources')?.length ? params.getAll('filteredSources').map((source) => Number(source)) : [],
});

const organizeSources = (sources) => {
    let temp = {};
    temp = sources.reduce((acc, { filter_group, source_title, source, source_id, cnt }) => {
        if(acc[filter_group] === undefined)
            acc[filter_group] = [];
        const label = source_title ? `${source_title} [${source}]` : source;
        acc[filter_group].push({
            label, 
            value: source_id, 
            cnt: cnt || null, 
            group: filter_group 
        });
        return acc;
    },{});

    let options = []
    Object.entries(temp).forEach(([key, value]) => {
        options.push({
            label: key,
            options: value
        });
    });

    return options;
}

const getSourceLabel = (source) => (
    source?.source_title && source?.source 
        ? `${source.source_title} [${source.source}]`
        : source?.source ? source.source 
            : 'Invalid Source'
)

const buildSourceOptions = (sourceIds,fullSourceList) => (
    sourceIds?.map(selectedSourceId => {
        const fullSourceData = fullSourceList?.find(source => source.source_id === selectedSourceId);
        const option = {
            label: getSourceLabel(fullSourceData),
            value: selectedSourceId
        }
        if(fullSourceData?.cnt)
            option.cnt = fullSourceData.cnt
        return option;
    }) || []
)

const convertArrayToMultiselect = (array) => (
    array?.map((element) => (
        {label: String(element), value: String(element)}
    )) || []
);

const isNumber = value => !isNaN(value);

const getSMMColumns = (metric, data) => {
    let sharedCols = [
        { 
            name: "period", 
            label: "Period", 
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const period = data[dataIndex].period;
                    switch(period) {
                        case 0:
                            return 'Initial Entry';
                        case 8:
                            return '8+ Days After Entry';
                        default:
                            return isNumber(period)
                                ? `${period} ${period > 1 ? 'Days' : 'Day'} After Entry`
                                : period 
                            ;
                    }
                }
            }
        },
        { 
            name: "collected", 
            label: "Collection Date/Time",
            options: {
                filter: false,
                sort: true
            }
        },
    ];

    let useCols = [];
    switch(metric) {
        case 'facebook':
            useCols = [
                { name: "reactions", label: "Reactions" },
                { name: "comments", label: "Comments" },
                { name: "shares", label: "Shares" },
                { name: "comment_plugin", label:"Comment Plugin" }
            ];
            break;
        case 'twitter':
            useCols = [
                { name: "tweets", label: "Tweets" },
                { name: "total_likes", label: "Total Likes" },
                { name: "top_likes", label: "Top Likes" },
                { name: "total_rts", label: "Total Retweets" },
                { name: "top_rts", label:"Top Retweets" },
                { name: "total_qts", label:"Total Quote Tweets" },
                { name: "top_qts", label: "Top Quotes Tweets" },
                { name: "total_replies", label: "Total Replies" },
                { name: "top_replies", label:"Top Replies" }
            ];
            break;
        case 'reddit':
            useCols = [
                { name: "posts", label: "Posts" },
                { name: "total_comments", label: "Total Comments" },
                { name: "top_comments", label:"Top Comments" },
                { name: "total_scores", label: "Total Scores" },
                { name: "top_score", label: "Top Score" },
                { name: "avg_ratio", label:"Average Vote Ratio" },
                { name: "top_ratio", label: "Top Vote Ratio"},
            ];
            break;
        default:
            return [];
    }

    return [
        ...sharedCols,
        ...useCols.map(col => ({
            ...col,
            options: {
                filter: false,
                sort: true
            }
        }))
    ];
}

// filteredSources is a subset of source search
// so, if filteredSources is set, use those selections as the sources for the api call
const transformCriteria = (criteria) => {
    const apiCriteria = {
        ...criteria,
        sources: criteria.filteredSources.length 
            ? criteria.filteredSources 
            : criteria.sources
    }
    delete apiCriteria.filteredSources;
    return apiCriteria
}

export { 
    getCriteriaFromParams, 
    getFilenameFromHeader,
    organizeSources, 
    buildSourceOptions, 
    convertArrayToMultiselect, 
    isNumber, 
    getSMMColumns,
    transformCriteria
};
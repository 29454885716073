import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import axios from '../axios';

const About = () => {
    const [totalArticles, setTotalArticles] = useState(null);

    useEffect(() => {
        document.title = 'SCOTUSApp - About';
        const getTotalArticles = async () => {
            try {
                const { data: { total_articles } } = await axios.get('/getTotalArticles');
                setTotalArticles(total_articles);
            }
            catch(err) {
                console.error(err);
            }
        }
        getTotalArticles();
    }, []);

    const downloadCols = [
        {
            "colname": "Article ID",
            "meaning": "The article's unique identifier"
        },
        {
            "colname": "Alt ID",
            "meaning": "An alternative identifier, formatted as YYYY-MM-DD_N, where \"YYYY-MM-DD\" is the article's publication date and N is \"Nth\" article published on that day (e.g., 2020-05-14_002 is the 2nd article published on May 14, 2020)"
        },
        {
            "colname": "Publication Date/Time",
            "meaning": "The article's date & time of publication (if not available, this is the date/time the article was entered into the database)"
        },
        {
            "colname": "Source Domain",
            "meaning": "The domain name associated with the article's source (e.g., New York Times = nytimes.com)"
        },
        {
            "colname": "Source Description",
            "meaning": "Formal name for the source associated with the article (not all sources have this)."
        },
        {
            "colname": "MBFC Bias",
            "meaning": "Media Bias Fact Check's bias classification of a source (ranging from Left to Right)"
        },
        {
            "colname": "MBFC Score",
            "meaning": "MBFC Bias numeric representation (range from [-1,1] from left-to-right wing)"
        },
        {
            "colname": "MBFC Z-Score",
            "meaning": "Z-Score of the MBFC Score (relative to the set of all MBFC sources existing in the dataset)."
        },
        {
            "colname": "MBFC Factual Reporting",
            "meaning": "Media Bias Fact Check's determination if source's reporting is based on factual evidence"
        },
        {
            "colname": "AllSides Bias",
            "meaning": "AllSides's bias classification of a source (ranging from Left to Right)"
        },
        {
            "colname": "AllSides Score",
            "meaning": "Allsides Bias numeric representation: -2 = Left, -1 = Center-Left, 0 = Center, 1 = Center-Right, 2 = Right"
        },
        {
            "colname": "AllSides Z-Score",
            "meaning": "Z-Score of the Allsides Score (relative to the set of all MBM sources existing in the dataset)."
        },
        {
            "colname": "AllSides Confidence",
            "meaning": "AllSides's certainty in their analysis of a source's bias."
        },
        {
            "colname": "AllSides Agreement",
            "meaning": "The number of votes from users who agree with AllSides's bias classification"
        },
        {
            "colname": "AllSides Disagreement",
            "meaning": "The number of votes from users who disagree with AllSides's bias classification"
        },
        {
            "colname": "MBM Score",
            "meaning": "Media Bias Monitor representation of a source's bias (range from [-2,2] from left-to-right wing)"
        },
        {
            "colname": "MBM Z-Score",
            "meaning": "Z-Score of the MBM score (relative to the set of all MBM sources existing in the dataset)."
        },
        {
            "colname": "URL",
            "meaning": "The article's web address"
        },
        {
            "colname": "Title",
            "meaning": "The article title"
        },
        {
            "colname": "Author",
            "meaning": "The article author"
        },
        {
            "colname": "Relevancy Score",
            "meaning": "The article's probability of being \"relevant\" to the federal Supreme Court. The closer to 1 it is, the more relevant it likely is."
        },
        {
            "colname": "Sentiment Score",
            "meaning": "An article's overall \"emotional sentiment\" (negative value = negative emotion, 0 = more neutral, positive = positive emotion)"
        },
        {
            "colname": "Sentiment Magnitude",
            "meaning": "The overall \"strength\" of the sentiment score (low magnitude = less emotion)"
        },
        {
            "colname": "Top Image Entity",
            "meaning": "The entity detected in an article's images with the highest confidence score (often the subject of the image)"
        },
        {
            "colname": "Entity Score",
            "meaning": "The \"confidence\" score associated with the top entity"
        },
        {
            "colname": "Keywords",
            "meaning": "The most important words in an article"
        },
        {
            "colname": "FB Reactions (split into Initial Entry / Day 1 / Day 7 / Post 7 Days columns - subsequent columns also follow this format)",
            "meaning": "# of reactions on Facebook posts linking to the article at the labelled point in time"
        },
        {
            "colname": "FB Comments",
            "meaning": "# of comments on Facebook posts linking to the article"
        },
        {
            "colname": "FB Shares",
            "meaning": "# of shares of Facebook posts linking to the article"
        },
        {
            "colname": "FB Comment Plugin",
            "meaning": "# of comments posted on article links using the Facebook Comment Plugin"
        },
        {
            "colname": "TW Tweets (split into Initial Entry / Day 1 / Day 7 columns - subsequent columns also follow this format)",
            "meaning": "# of unique Twitter tweets linking to an article (does not count retweets)"
        },
        {
            "colname": "TW Total Favorites",
            "meaning": "# of favorites across all tweets linking to an article"
        },
        {
            "colname": "TW Total Retweets",
            "meaning": "# of retweets across all tweets linking to an article"
        },
        {
            "colname": "TW Total Quote Tweets",
            "meaning": "# of quote tweets across all tweets linking to an article"
        },
        {
            "colname": "TW Total Replies",
            "meaning": "# of replies across all tweets linking to an article"
        },
        {
            "colname": "TW Top Favorites",
            "meaning": "The highest # of favorites belonging to a single tweet linking to an article"
        },
        {
            "colname": "TW Top Retweets",
            "meaning": "The highest # of retweets belonging to a single tweet linking to an article"
        },
        {
            "colname": "TW Top Quote Tweets",
            "meaning": "The highest # of quote tweets belonging to a single tweet linking to an article"
        },
        {
            "colname": "TW Top Replies",
            "meaning": "The highest # of replies belonging to a single tweet linking to an article"
        },
        {
            "colname": "RDT Posts (split into Initial Entry / Day 1 / Day 7 / Post 7 Days columns - subsequent columns also follow this format)",
            "meaning": "The # of Reddit posts linking to an article"
        },
        {
            "colname": "RDT Total Comments",
            "meaning": "The # of comments across all Reddit posts linking to an article"
        },
        {
            "colname": "RDT Total Scores",
            "meaning": "The sum of the \"score\" of each Reddit post linking to an article, where the score is the net number of upvotes (increase score) vs. downvotes (decrease score) belonging to a post"
        },
        {
            "colname": "RDT Top Comments",
            "meaning": "The highest # of comments belonging to a single Reddit post linking to an article"
        },
        {
            "colname": "RDT Top Score",
            "meaning": "The highest score belonging to a single Reddit post linking to an article"
        },
        {
            "colname": "RDT Top Ratio",
            "meaning": "The highest score ratio belonging to a single Reddit post linking to the article, where the score ratio = (# of upvotes / sum of upvotes and downvotes)"
        },
        {
            "colname": "RDT Average Ratio",
            "meaning": "The average score ratio across all Reddit posts linking to an article"
        },
        {
            "colname": "MBM demographic columns (various)",
            "meaning": "Media Bias Monitor source audience data broken down into several demographic groups as percentages. Categories include: Political Alignment, Political Engagement, Age, Income, Race, Gender, Education."
        }
    ];

    return (
        <Container className='my-3'>
            <div className='terms pane w-100 bg-light text-dark'>
                <header className='text-center mb-4'>
                    <h2>About SCOTUSApp</h2>
                </header>
                <p>
                    SCOTUSApp is an academic research web application commissioned by {' '} 
                    <a className='link text-dark' target='_blank' rel='noreferrer' href="https://polisci.as.uky.edu/users/jpwede2">Dr. Justin Wedeking</a> and {' '} 
                    <a className='link text-dark' target='_blank' rel='noreferrer' href="https://polisci.as.uky.edu/users/mazi223">Dr. Michael Zilis</a> of the University of Kentucky Political Science Department. 
                    The app is supported by a grant from the National Science Foundation (Award #1849286) and the Department of Political Science at the University of Kentucky. 
                    SCOTUSApp finds, stores, and analyzes online articles pertaining to the United States Supreme Court. It captures news stories, blog posts, and opinion pieces. 
                    It is currently developed by Evan Cole.
                </p>
                <hr />
                <section>
                    <h3>How It Works</h3>
                    <p>The application consists of three major components:</p>
                    <h4>The Article Collector</h4>
                    <p>Running behind the scenes is a script built to run continuously for the purpose of collecting and storing article data. Articles are gathered from three different sources:</p>
                    <ul>
                        <li><a className='link text-dark' target='_blank' rel='noreferrer' href="https://www.google.com/alerts">Google Alerts</a> RSS Feeds set to collect articles with Supreme Court-related terms</li>
                        <li><a className='link text-dark' target='_blank' rel='noreferrer' href="https://newsapi.org/">NewsAPI</a> feeds based on search results for Supreme Court-related terms</li>
                        <li>Specific "Topic Sites" - these are web pages from major publications specifically containing Supreme Court articles, such as <a className='link text-dark' target='_blank' rel='noreferrer' href="https://www.cnn.com/specials/politics/supreme-court-nine">this one</a> from CNN.</li>
                    </ul>
                    <p>These mediums provide article links and often metadata (author, date, etc.) for the articles. Upon gathering this information, articles are then run through our scraping methods to extract the full text and its keywords, any missing metadata, and any images associated with an article.</p> 
                    <p>Once an article's data has been fully collected, it is verified for relevancy to the U.S. Supreme Court.  Lower courts, state Supreme Courts, and foreign Supreme Courts often appear in the feeds, but these are filtered as irrelevant absent some connection to the USSC. This is primarily done by running the article's text and title through a text classifier powered by machine learning techniques. As of May 2020, our tests indicate an accuracy rate above 95%.</p>
                    <p>If deemed relevant, the article is then analyzed for additional data:</p>
                    <ul>
                        <li>Sentiment score (and accompanying magnitude) of the article text, powered by the <a className='link text-dark' target='_blank' rel='noreferrer' href="https://cloud.google.com/natural-language/">Google Cloud Natural Language API</a>.</li>
                        <li>Social Media popularity metrics across Facebook, Twitter, and Reddit - likes, retweets, favorites, shares, # of comments, etc. This data is collected at three different intervals - upon entry into the database, 1 day after publication, and 7 days after publication. This is powered by each platform's own APIs.</li>
                        <li>Significant similarity between the texts of any recently-added articles in the database (currently, articles published within 3 days before or after the examined article's publication are examined). This is represented by a similarity score shared between any two similar articles, calculated through the use of <a className='link text-dark' target='_blank' rel='noreferrer' href="https://en.wikipedia.org/wiki/Cosine_similarity">cosine similarity</a> methods.</li>
                        <li>Political bias data of an article's source/publication, powered by information courtesy of <a className='link text-dark' target='_blank' rel='noreferrer' href="https://mediabiasfactcheck.com/">Media Bias Fact Check</a> and <a className='link text-dark' target='_blank' rel='noreferrer' href="https://www.allsides.com/media-bias/media-bias-ratings">AllSides.</a></li>
                    </ul>
                    <p>Upon analysis, all of the data associated with a new article is stored in our database.</p>
                    <h4>The Web Application</h4>
                    <p>The Web Application is the front-facing aspect of the project, as it allows users to search, view, and download our dataset. Users may download the entire database or the results of a targeted search. Searches may be refined by date, keyword, or source.  Downloads consist of a ZIP file containing a single CSV with article metadata &amp; analysis, and TXT files of each article's full text named according to the article's ID. Users must make an account and be approved by the project's administrators prior to access.</p>
                    <h4>The Database</h4>
                    <p>This is where all of the project's data is stored. Data collection began in Fall 2017 and is ongoing. As of right now, <b>{totalArticles?.toLocaleString() || null}</b> articles have been collected. Over time, various improvements and adjustments have been made, and thus this should be taken into consideration by users.</p>
                </section>
                <hr />
                <section>
                    <h3>How to Use the Application</h3>
			        <p>Users must first make an account to access the application. Upon registration, our administrators will be notified to either approve or deny your access. You will be notified of their decision.</p>
			        <p>
                        Upon approval, use of the application can begin. Users can search for articles by text (title, keyword, and/or full text), date range, source, and/or article ID. 
                        Sources can be filtered through two different methods: either by directly searching for specific sources with the "Sources" search box (multiple sources must be separated by spaces), or checking sources within the "Sources" sidebar and hitting "Apply Filter." 
                        Search results can be downloaded by clicking the "Download Results" button. To restart a search, either hit the "Restart" button or click the main "SCOTUSApp" header. By default, the entire dataset is displayed in the order of descending Article ID. 
                        Results can be sorted by clicking the headers in the results table.
                    </p> 
			        <p>To view more information about an article, click its title in the results table.  This will take you to the article's individual page, where the data is neatly formatted for perusing. For legal reasons, only the first third of an article's text is displayed on this page.</p>
                </section>
                <hr />
                <section>
                    <h3>Understanding Downloads</h3>
                    <p>
                        Result CSVs contain data that may not be intuitive - listed below are the column definitions from left to right in the CSV header. 
                        Note: some fields may be blank due to unavailable / inapplicable data.
                    </p>
                    <div className='scrollTable'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th>Column Name</th>
                                    <th>Explanation</th>
                                </tr>
                            </thead>
                            <tbody>
                            { downloadCols.map(({colname, meaning}) => (
                                <tr key={colname}>
                                    <td>{colname}</td>
                                    <td>{meaning}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </section>
            </div>
        </Container>
    )
}

export default About;
import { Outlet } from "react-router-dom";
import Header from "./Header.component";
import Footer from './Footer.component';

const Layout = () => {
    return (
        <main className="App">
            <Header />
            <div id='content'>
                <Outlet />
            </div>
            <Footer />
        </main>
    )
}

export default Layout
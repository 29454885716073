import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();

    return (
        <Navbar expand="lg" bg="dark" variant="dark" id='footer' className='d-flex justify-content-center align-items-center mt-3'>
            <section className='p-2 text-center'>
                { location?.pathname?.startsWith('/article/') && ([ // source bias credits only appear on article pages
                    <article key='allsides-attr' className='mb-2'>
                        <a rel="license" target='_blank' href="http://creativecommons.org/licenses/by-nc/4.0/">
                            <img style={{marginBottom: '10px'}} alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
                        </a>
                        <p>
                            <a className='link text-white' target='_blank'  {...{ 'xmlns:dct': "http://purl.org/dc/terms/" }} href="https://www.allsides.com/media-bias/media-bias-ratings" rel="dct:source">
                                <span {...{ 'xmlns:dct': "http://purl.org/dc/terms/" }} href="http://purl.org/dc/dcmitype/Dataset" property="dct:title" rel="dct:type">AllSides Media Bias Ratings</span>
                            </a> by&nbsp;
                            <a className='link text-white' target='_blank' {...{ 'xmlns:cc': "http://creativecommons.org/ns#" }} href="https://www.allsides.com/unbiased-balanced-news" property="cc:attributionName" rel="cc:attributionURL">AllSides.com</a> are 
                            licensed under a <a className='link text-white' target='_blank'  rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-NonCommercial 4.0 International License</a>. 
                            <br />You may use this data for research or noncommercial purposes provided you include this attribution.
                        </p>
                    </article>,
                    <article key='mbfc-attr' className='mb-2'>
                        Media Bias Fact Check data courtesy of <a className='link text-white' target='_blank' href='https://mediabiasfactcheck.com/'>MediaBiasFactCheck.com</a>.
                    </article>,
                    <article key='mbm-attr' className='mb-2'>
                        Media Bias Monitor data courtesy of <a className='link text-white' target='_blank' href='https://homepages.dcc.ufmg.br/~filiperibeiro/'>Filipe N. Ribiero</a>'s&nbsp; 
                        <a className='link text-white' target='_blank' href='https://twitter-app.mpi-sws.org/media-bias-monitor'>Media Bias Monitor</a>.
                    </article>
                ])}
                <article className='mb-2'>
                    Use of this application must be in accordance with the <Link className='link text-white' to='/terms-of-use'>SCOTUSApp Terms of Use</Link>.
                </article>
                <article>
                    <a className='link text-white' target="_blank" href="https://icons8.com/icon/XDZ1Tyne10jk/supreme-court">Supreme Court</a> icon by <a className='link text-white' target="_blank" href="https://icons8.com">Icons8</a>
                </article>
            </section>
        </Navbar>
    )
}

export default Footer;
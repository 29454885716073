import axios from 'axios';

const {
    REACT_APP_DOMAIN
} = process.env;

export default axios.create({
    baseURL: REACT_APP_DOMAIN
})

export const axiosPrivate = axios.create({
    baseURL: REACT_APP_DOMAIN,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
})
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { Row, Col, Container, Form, Alert, Button } from 'react-bootstrap';
import useAuth from "../../hooks/useAuth";
import axios from '../../axios';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const initialValues = {newPassword: '', confirmNewPassword: ''};
    const MAX_CHARS = 8;

    useEffect(() => { document.title = 'SCOTUSApp - Forgot Password' }, []);

    const validate = values => {
        const controlChars  = /[\x00-\x1F\x7F]/;
        const errors = {};
        if(!values.newPassword)
            errors.newPassword = 'Required.';
        else if(values.newPassword.length < MAX_CHARS)
            errors.newPassword = `Password is not long enough (minimum ${MAX_CHARS} characters).`;
        else if (controlChars.test(values.password))
            errors.newPassword = 'Password contains invalid characters.';

        if(!values.confirmNewPassword)
            errors.confirmNewPassword = 'Required.';
        else if(values.newPassword !== values.confirmNewPassword)
            errors.confirmPassword = 'New Password and Confirm New Password fields do not match.';

        return errors;
    }

    return (
        <Container>
            <section id='forgot-password' className="pane bg-light text-dark">
                <header className='text-center mb-3'>
                    <h2>Reset Password</h2>
                </header>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                        try {
                            await axios.post("/resetPassword/", {...values, token }, {
                                headers: {'Content-Type':'application/json'},
                            });
                            const successMsg = 'Password successfully reset - please log in with your new password.';
                            navigate("/login", {
                                state: { msg: successMsg }
                            });
                        }
                        catch(err) {
                            console.error(err);
                            const errmsg = err.response ? err.response.data : err.message;
                            actions.setFieldError('newPassword',errmsg);
                        }
                        finally {
                            actions.setSubmitting(false);
                        }
                    }}
                >
                {formik => (
                    <Form onSubmit={formik.handleSubmit} className="ps-3 pe-3 pb-3">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="newpass-field">
                                    <Form.Label>New Password</Form.Label>
                                    {formik.touched.newPassword && formik.errors.newPassword ? 
                                        (<Alert key="newPass-err" variant="danger">{formik.errors.newPassword}</Alert>) : null}
                                    <Form.Control type="password" name="newPassword"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.newPassword} />
                                    <Form.Text id="passwordHelpBlock" muted>
                                        Your password must be at least {MAX_CHARS} characters.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlId="newpass-confirm-field">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? 
                                        (<Alert key="confirmPass-err" variant="danger">{formik.errors.confirmNewPassword}</Alert>) : null}
                                    <Form.Control type="password" name="confirmNewPassword"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmNewPassword} />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="d-flex justify-content-center">
                                    <Button className="mt-4" variant='primary' type="submit" disabled={formik.isSubmitting}>
                                        Reset Password
                                    </Button>
                                </Form.Group>   
                            </Col>
                        </Row>
                    </Form>
                )}
                </Formik>
            </section>
        </Container>
    )

}

export default ResetPassword;
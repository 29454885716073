import React, { useEffect } from 'react';
import { Container, Button, Form, Row, Col, Stack, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik';
import axios from '../axios';
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Login = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if(auth?.user) {
            navigate(from, { replace: true });
        }
        document.title = 'SCOTUSApp - Login';
    },[]);

    const validate = values => {
        const emailre = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const errors = {};
        if(!values.email || !values.password)
            errors.msg = 'Email and password required.';
        else if(!emailre.test(values.email))
            errors.msg = 'Invalid email.';
        return errors;
    };

    return (
        <Container>
            <section id='login' className='pane bg-light text-dark'>
                <header className='text-center'>
                    <h2>Login</h2>
                </header>
                <article className='my-4'>
                    <p>SCOTUSApp can only be used once you have created an account and have been verified by our administrators.</p>
                    <p>Use of this application must be in accordance with the <Link to='/terms-of-use' className='link text-dark'>SCOTUSApp Terms of Use.</Link></p>
                </article>
                {location.state && location.state.msg && (
                    <Alert key="register-success" variant="success">{location.state.msg}</Alert>
                )}
                <Formik
                    initialValues={{email:'',password:''}}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                        try {
                            const resp = await axios.post("/login/",values, {
                                headers: {'Content-Type':'application/json'},
                                withCredentials: true
                            });
                            const auth = resp.data;

                            setAuth(auth);
                            navigate(from, { replace: true });
                        }
                        catch(err) {
                            const errmsg = err.response ? err.response.data : err.message;
                            actions.setFieldError("msg",errmsg);
                        }
                        actions.setSubmitting(false);
                    }}
                >
                {formik => (
                    <Form onSubmit={formik.handleSubmit}>
                        {formik.errors.msg && (
                            <Alert key="login-err" variant="danger">
                                {formik.errors.msg}
                            </Alert>
                        )}
                        <Row>
                            <Col xs={12}>
                                <Form.Group className='d-flex align-items-center' controlId="email-field">
                                    <Form.Label className='inline-label'>
                                        <FontAwesomeIcon icon={faUser} />
                                    </Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email" 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className='d-flex align-items-center' controlId="password-field">
                                    <Form.Label className='inline-label'>
                                        <FontAwesomeIcon icon={faKey} />
                                    </Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        name="password" 
                                        placeholder="Password" 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Stack direction="horizontal" gap={2} className="justify-content-center my-2">
                            <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                Sign In
                            </Button>
                            <Button as={Link} variant="danger" type='button' to="/register">Register</Button>
                        </Stack>
                        <div className='d-flex justify-content-center'>
                            <Link to='/forgot-password/' className='link text-secondary'>
                                <small>Forgot Password</small>
                            </Link>
                        </div>
                    </Form>
                )}
                </Formik>
            </section>
        </Container>
    );

};

export default Login;
import { useState } from 'react';
import CustomModal from "./CustomModal.component";
import { Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { useFormik } from 'formik';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ChangePassword = ({ setMsg }) => {
    const [showModal, setShowModal] = useState(false);
    const PASSWORD_MIN = 8;
    const axiosPrivate = useAxiosPrivate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        validate: (values) => {
            const errors = {};

            if(!values.currentPassword)
                errors.currentPassword = 'Required.';

            if(!values.newPassword)
                errors.newPassword = 'Required.';
            else if(values.newPassword.length < PASSWORD_MIN)
                errors.newPassword = `Password is not long enough (minimum ${PASSWORD_MIN} characters).`;

            if(!values.confirmNewPassword)
                errors.confirmNewPassword = 'Required.';
            else if(values.newPassword !== values.confirmNewPassword)
                errors.confirmNewPassword = 'New Password and Confirm New Password fields do not match.';

            return errors;
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            try {
                await axiosPrivate.post('/all/changePassword/', values);
                setMsg({
                    text: 'Password has been updated.',
                    variant: 'success',
                    dismissed: false
                });
                setShowModal(false);
            }
            catch(err) {
                console.error(err);
                actions.setFieldError('currentPassword', err?.response?.data || err?.message);
            }
            finally {
                actions.setSubmitting(false);
            }
        }
    });

    const modalForm = (
        <Form id='pw-change-form' onSubmit={formik.handleSubmit}>
            <Row>
                <Col xs={12}>
                    <Form.Group controlId='currpass-field'>
                        <Form.Label>Current Password</Form.Label>
                        {formik.touched.currentPassword && formik.errors.currentPassword && (
                            <Alert variant='danger'>{formik.errors.currentPassword}</Alert>
                        )}
                        <Form.Control 
                            type='password'
                            name='currentPassword'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group controlId='newpass-field'>
                        <Form.Label>New Password</Form.Label>
                        {formik.touched.newPassword && formik.errors.newPassword && (
                            <Alert variant='danger'>{formik.errors.newPassword}</Alert>
                        )}
                        <Form.Control 
                            type='password'
                            name='newPassword'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            Minimum {PASSWORD_MIN} characters.
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group controlId='confirm-newpass-field'>
                        <Form.Label>Confirm New Password</Form.Label>
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                            <Alert variant='danger'>{formik.errors.confirmNewPassword}</Alert>
                        )}
                        <Form.Control 
                            type='password'
                            name='confirmNewPassword'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmNewPassword}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
    
    return (
        <>
            <Button variant='secondary' type='button' onClick={() => setShowModal(true)}>
                Change Password
            </Button>
            <CustomModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                header={<h2>Change Password</h2>}
                body={modalForm}
                footer={[
                    <Button key='pw-change' variant='primary' type='submit' form='pw-change-form' disabled={formik.isSubmitting}>Submit</Button>,
                    <Button key='pw-change-cancel' type='button' variant='secondary' onClick={() => setShowModal(false)} disabled={formik.isSubmitting}>Cancel</Button>
                ]}
            />
        </>
    )
}

export default ChangePassword;
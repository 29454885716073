import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from './components/Login.component';
import Register from './components/Register.component';
import RequireAuth from "./components/auth/RequireAuth.component";
import PersistLogin from './components/auth/PersistLogin.component';
import Layout from './components/template/Layout.component';
import Home from './components/home/Home.component';
import Unauthorized from './components/template/Unauthorized.component';
import ApproveNewUser from './components/ApproveNewUser.component';
import ArticlePage from './components/article-page/ArticlePage.component';
import ResetPasswordEmailInput from './components/reset-password/ResetPasswordEmailInput.component';
import ResetPassword from './components/reset-password/ResetPassword.component';
import Profile from './components/profile/Profile.component';
import TermsOfUse from './components/TermsOfUse.component';
import About from './components/About.component';

const roles = {
  'unapproved': 0,
  'approved': 1,
  'admin': 2
};

function App() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password/" element={<ResetPasswordEmailInput />} />
          <Route path="/forgot-password/reset/:token" element={<ResetPassword />} />
          <Route path="/404" element={<Unauthorized />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path='/about/' element={<About />} />
          {/* private routes */}
          <Route element={<RequireAuth minauth={roles.unapproved} />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<RequireAuth minauth={roles.approved} />}>
            <Route path="/" element={<Home />} />
            <Route path="/article/:id" element={<ArticlePage/>} />
          </Route>
          <Route element={<RequireAuth minauth={roles.admin} />}>
            <Route path="/approve/:id" element={<ApproveNewUser />} />
          </Route>
          <Route path="*" element={<Unauthorized />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App;

import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import axios from '../../axios';

const ResetPasswordEmailInput = () => {
    const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => { document.title = 'SCOTUSApp - Forgot Password' }, []);

    const validate = (values) => {
        const emailre = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const errors = {};
        if(!values.email)
            errors.msg = 'Required field.';
        else if(!emailre.test(values.email))
            errors.msg = 'Not a valid email.';
        
        return errors;
    };

    return (
        <Container className="d-flex justify-content-center">
            <section className="pane bg-light text-dark">
                <header className="text-center">
                    <h2>Forgot Password</h2>
                </header>
                <article className='my-4'>
                    <p>Enter the email associated with your SCOTUSApp account, and you will receive a link to reset your password.</p>
                </article>
                {successMsg && (<Alert key="success" variant="success">{successMsg}</Alert>)}
                <Formik
                    initialValues={{ email: '' }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, actions) => {
                        try {
                            await axios.post("/sendResetLink/",values, {
                                headers: {'Content-Type':'application/json'},
                            });
                            setSuccessMsg(`If an account exists, a password reset link was sent to ${values.email}.`);
                        }
                        catch(err) {
                            const errmsg = err.response ? err.response.data : err.message;
                            actions.setFieldError("msg",errmsg);
                        }
                        actions.setSubmitting(false);
                    }}
                >
                {formik => (
                    <Form inline="true" onSubmit={formik.handleSubmit}>
                        {formik.errors.msg && (
                            <Alert key="login-err" variant="danger">
                                {formik.errors.msg}
                            </Alert>
                        )}
                        <Row>
                            <Col sm={12}>
                                <Form.Group className='d-flex align-items-end' controlId='forgot-email-field'>
                                    <Form.Control 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email" 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    <Button className='ms-2' variant="primary" type="submit" disabled={formik.isSubmitting} onClick={() => { setSuccessMsg(''); }}>
                                        Submit
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                )}
                </Formik>
            </section>
        </Container>
    );

};

export default ResetPasswordEmailInput;
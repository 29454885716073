import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ minauth }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if(auth?.user?.authority === undefined) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    else if(auth.user.authority < minauth ) {
        return <Navigate to="/unauthorized" />
    }
    return <Outlet />;
}

export default RequireAuth;
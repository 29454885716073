import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

const Unauthorized = () => {
    useEffect(() => { document.title = 'SCOTUSApp - Error' }, []);

    return (
        <>
            <Container className='d-flex align-items-center'>
                <section className="bg-dark pane w-100 text-center">
                    <header>
                        <h2 className='text-danger'>Error</h2>
                    </header>
                    <h4 className='my-5'>Page not found.</h4>
                </section>
            </Container>
        </>
    )
}

export default Unauthorized